import currenciesMetadata from "constants/currenciesMetadata";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Avatar, Box, Button, Grid, Stack, Typography, styled, useMediaQuery, useTheme } from "@mui/material";
import imageLightning from "assets/images/lightning.png";
import imageSats from "assets/images/sats.png";
import CurrencyIcon from "components/elements/CurrencyIcon";
import MaskedModeWrapper from "components/elements/MaskedModeWrapper";
import { BarLineChart, CoinsStacked } from "components/icons";
import ProfitBox from "components/modules/ProfitBox";
import SatsView from "components/modules/SatsView";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { IDcaStrategiesSummaries, IGridStrategiesSummaries, ICashbackSatsSummaries } from "types/types";
import { getColorByMode, splitCurrencyPair } from "utils";

import LinearDifference from "../../Item/LinearDifference";
import PercentDifferenceBox from "../PercentDifferenceBox";

interface IProps {
  isGrid?: boolean;
  isSats?: boolean;
  summary: IDcaStrategiesSummaries | IGridStrategiesSummaries | ICashbackSatsSummaries;
}
const getCurrencyScale = (currencyEnum: string) => currenciesMetadata.currencies[currencyEnum as keyof typeof currenciesMetadata.currencies]?.displayed_scale ?? 8;

export const SectionBox = styled(Box)(({ theme }) => {
  const { colors } = useMUIThemeModeContext();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return { padding: isMobile ? "8px" : "16px", backgroundColor: colors.gray50, borderRadius: "10px" };
});

const Item = ({ isGrid, isSats, summary }: IProps) => {
  const { t } = useTranslation<string>();
  const theme = useTheme();
  const { colors } = useMUIThemeModeContext();
  const { baseCurrency, counterCurrency } = splitCurrencyPair("currencyPair" in summary ? summary.currencyPair : "");
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const header = (value: number) => (
    <Stack direction={"row"} spacing={isMobile ? 0.5 : 1}>
      <Stack direction={"row"} alignItems="center">
        <CurrencyIcon
          style={{
            outline: `2px solid ${colors.white}`,
            borderRadius: "50%",
            width: isMobile ? "18px" : "24px",
            height: isMobile ? "18px" : "24px",
            zIndex: 1,
          }}
          name={baseCurrency.toLocaleLowerCase()}
        />
        <CurrencyIcon
          style={{
            marginLeft: "-2px",
            width: isMobile ? "18px" : "24px",
            height: isMobile ? "18px" : "24px",
          }}
          name={counterCurrency.toLocaleLowerCase()}
        />
      </Stack>
      <MaskedModeWrapper color={colors.gray950} fontSize={isMobile ? "0.80rem" : "1rem"} fontWeight={500}>
        <SatsView
          color={colors.gray950}
          fontSize={isMobile ? "0.80rem" : "1rem"}
          fontWeight={500}
          value={value}
          currency={baseCurrency}
          scale={getCurrencyScale(baseCurrency)}
          withZeros={!!value}
        />
      </MaskedModeWrapper>
    </Stack>
  );

  const dcaSection = () => {
    const summaryData = summary as IDcaStrategiesSummaries;
    const valueDifference = summaryData.currentValueDifference ?? 0;
    const prefix = valueDifference === 0 ? "" : summaryData.currentValueDifference >= 0 ? "+\u00a0" : "-\u00a0";

    return (
      <Stack height={"100%"} spacing={"2px"}>
        <SectionBox p={0} height={"100%"}>
          <Stack spacing={1}>
            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
              {header(summaryData.totalAmount)}
              <PercentDifferenceBox value={summaryData.currentValuePercentDifference ?? 0} isMobile={isMobile} />
            </Stack>
            <LinearDifference currentValue={summaryData.currentValue} value={summaryData.totalInvested} />
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Stack direction={"row"} flexWrap={"wrap"}>
                {!isMobile && <CoinsStacked color="inherit" sx={{ fontSize: 20, mr: 1 }} />}
                <MaskedModeWrapper color={colors.gray950} fontSize={isMobile ? "0.7rem" : "0.85rem"} fontWeight={500}>
                  <SatsView
                    color={colors.gray950}
                    fontSize={isMobile ? "0.7rem" : "0.85rem"}
                    fontWeight={500}
                    value={summaryData.totalInvested ?? 0}
                    currency={counterCurrency}
                    scale={getCurrencyScale(counterCurrency)}
                    withZeros={!!summaryData.totalInvested}
                  />
                </MaskedModeWrapper>
                <Stack direction={"row"} spacing={0.5}>
                  <ArrowForwardIcon sx={{ fontSize: isMobile ? "0.7rem" : "0.85rem", marginTop: "3px !important", marginLeft: "3px" }} />
                  <MaskedModeWrapper color={colors.gray950} fontSize={isMobile ? "0.7rem" : "0.85rem"} fontWeight={500}>
                    <SatsView
                      color={colors.gray950}
                      fontSize={isMobile ? "0.7rem" : "0.85rem"}
                      fontWeight={500}
                      value={summaryData.currentValue ?? 0}
                      currency={counterCurrency}
                      scale={getCurrencyScale(counterCurrency)}
                      withZeros={!!summaryData.currentValue}
                    />
                  </MaskedModeWrapper>
                </Stack>
              </Stack>
              <MaskedModeWrapper color={colors.gray950} fontSize={isMobile ? "0.7rem" : "0.85rem"} fontWeight={500}>
                <SatsView
                  sx={{ pl: 2 }}
                  color={colors.gray950}
                  fontSize={isMobile ? "0.7rem" : "0.85rem"}
                  fontWeight={500}
                  value={Math.abs(valueDifference)}
                  currency={counterCurrency}
                  scale={getCurrencyScale(counterCurrency)}
                  withZeros={!!valueDifference}
                  prefix={prefix}
                />
              </MaskedModeWrapper>
            </Stack>
          </Stack>
        </SectionBox>
      </Stack>
    );
  };

  const gridSection = () => {
    const summaryData = summary as IGridStrategiesSummaries;

    return (
      <Stack height={"100%"} spacing={"2px"}>
        <SectionBox p={0} height={"100%"}>
          <Stack spacing={1} justifyContent={"space-between"} height={"100%"}>
            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} alignContent={"center"}>
              {header(summaryData.totalBaseCurrencyProfit)}
              <ProfitBox
                value={summaryData.totalBaseCurrencyProfitCurrentValue ?? 0}
                currency={counterCurrency}
                scale={getCurrencyScale(counterCurrency)}
                showIcon={isMobile}
              />
            </Stack>
            {!isMobile && (
              <Stack direction={"row"} spacing={1} alignItems={"center"}>
                <BarLineChart color="inherit" sx={{ fontSize: 20 }} />
                <Typography color={colors.gray950} fontSize={"0.9rem"}>
                  {t("summaries.gridTotalProfit")}
                </Typography>
              </Stack>
            )}
          </Stack>
        </SectionBox>
      </Stack>
    );
  };

  const cashbackSatsSection = () => {
    const summaryData = summary as ICashbackSatsSummaries;
    const valueDifference = summaryData.availableAmount ?? 0;
    const prefix = valueDifference === 0 ? "" : summaryData.availableAmount >= 0 ? "+\u00a0" : "-\u00a0";

    return (
      <Stack height={"100%"} spacing={"2px"}>
        <SectionBox p={0} height={"100%"}>
          <Stack spacing={1}>
            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
              <Stack direction={"row"} spacing={isMobile ? 0.5 : 1}>
                <Avatar
                  src={imageSats}
                  sx={{
                    width: isMobile ? "18px" : "24px",
                    height: isMobile ? "18px" : "24px",
                    outline: `2px solid ${colors.white}`,
                  }}
                />
                <MaskedModeWrapper color={colors.gray950} fontSize={isMobile ? "0.80rem" : "1rem"} fontWeight={500}>
                  <SatsView
                    color={colors.gray950}
                    fontSize={isMobile ? "0.80rem" : "1rem"}
                    fontWeight={500}
                    value={summaryData.totalAmount}
                    currency={"cashback satů"}
                    scale={0}
                    withZeros={!!summaryData.totalAmount}
                  />
                </MaskedModeWrapper>
              </Stack>
              <Stack py={0.1} direction={"row"} spacing={1} alignItems={"center"}>
                {isMobile && <Avatar  src={imageLightning} sx={{ width: "20px", height: "20px" }} />}
                <Button
                  sx={{ border: `1px solid ${colors.gray300}`, backgroundColor: getColorByMode(theme, "white", "black") }}
                  onClick={() => console.log("show sat cashback details")}
                  component={Link}
                  to="#cashback-saty"
                  color="inherit"
                  size="small"
                  variant="outlined">
                  {"Detaily"}
                </Button>
              </Stack>
            </Stack>
            <LinearDifference currentValue={summaryData.totalAmount} value={summaryData.withdrawnAmount} isSats />
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Stack direction={"row"} flexWrap={"wrap"}>
                {!isMobile && <Avatar  src={imageLightning} sx={{ width: "20px", height: "20px", mr: 1 }} />}
                <MaskedModeWrapper color={colors.gray950} fontSize={isMobile ? "0.7rem" : "0.85rem"} fontWeight={500}>
                  <SatsView
                    color={colors.gray950}
                    fontSize={isMobile ? "0.7rem" : "0.85rem"}
                    fontWeight={500}
                    value={summaryData.withdrawnAmount ?? 0}
                    currency={"satů vyplaceno"}
                    scale={0}
                    withZeros={!!summaryData.withdrawnAmount}
                  />
                </MaskedModeWrapper>
              </Stack>
              <MaskedModeWrapper color={colors.gray950} fontSize={isMobile ? "0.7rem" : "0.85rem"} fontWeight={500}>
                <SatsView
                  sx={{ pl: 2 }}
                  color={colors.gray950}
                  fontSize={isMobile ? "0.7rem" : "0.85rem"}
                  fontWeight={500}
                  value={valueDifference}
                  currency={"satů"}
                  scale={0}
                  withZeros={!!valueDifference}
                  prefix={prefix}
                />
              </MaskedModeWrapper>
            </Stack>
          </Stack>
        </SectionBox>
      </Stack>
    );
  };

  const getContent = () => {
    switch (true) {
      case isSats:
        return cashbackSatsSection();
      case isGrid:
        return gridSection();
      default:
        return dcaSection();
    }
  };

  return (
    <Grid item xs={12} lg={6}>
      {getContent()}
    </Grid>
  );
};

export default Item;
