import { FormControlLabel, Stack } from "@mui/material";
import Modal from "components/elements/Modal";
import Switch from "components/elements/Switch";
import { useUserDataContext } from "context/UserDataContext";
import { useTranslation } from "react-i18next";

interface IProps {
  open: boolean;
  onCancel: () => void;
}

const EditModal = ({ open, onCancel }: IProps) => {
  const { t } = useTranslation<string>();
  const { summariesSettings, handleChangeSummarySetting, creditsRechargeType } = useUserDataContext();

  const handleSwithChange = (key: string, childKey?: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked;
    handleChangeSummarySetting?.(key, newValue);

    if (childKey && newValue === false) handleChangeSummarySetting?.(childKey, newValue);
  };

  const item = (key: string, disabled = false, childKey?: string) => {
    return (
      <FormControlLabel
        sx={{ display: "flex", alignItems: "flex-start", ml: 0 }}
        control={
          <Switch
            sx={{ mr: 2, mt: 0.5 }}
            checked={summariesSettings[key as keyof typeof summariesSettings]}
            disabled={disabled}
            onChange={handleSwithChange(key, childKey)}
          />
        }
        label={t(`summaries.modal.${key}`)}
      />
    );
  };

  return (
    <Modal
      open={open}
      size="xs"
      onCancel={onCancel}
      title={t("summaries.modal.title")}
      withCloseButton
      disableCancelButton>
      <Stack spacing={2}>
        {creditsRechargeType && item("cashbackSats", false)}
        <Stack spacing={0.5}>
          {item("gridSummaries", false, "gridSummariesIncludingDisabled")}
          {item("gridSummariesIncludingDisabled", !summariesSettings.gridSummaries)}
        </Stack>
        <Stack spacing={0.5}>
          {item("dcaSummaries", false, "dcaSummariesIncludingDisabled")}
          {item("dcaSummariesIncludingDisabled", !summariesSettings.dcaSummaries)}
        </Stack>
      </Stack>
    </Modal>
  );
};

export default EditModal;
