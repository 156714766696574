import React from "react";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Avatar, Box, Button, Grid, Skeleton, Stack, Typography } from "@mui/material";
import imageLightning from "assets/images/lightning.png";
import imageSats from "assets/images/sats.png";
import Card2 from "components/elements/Card2";
import MaskedModeWrapper from "components/elements/MaskedModeWrapper";
import SectionCard from "components/elements/SectionCard";
import Tooltip from "components/elements/Tooltip";
import { Wallet } from "components/icons";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { useAPICashbackSats } from "hooks/useAPI";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { renderNumber } from "utils/formatter";

import Transactions from "./Transactions";

const infoCardIcon = (key: string) => {
  switch (key) {
    case "totalAmount":
      return <Avatar src={imageSats} sx={{ width: "24px", height: "24px" }} />;
    case "withdrawnAmount":
      return <Wallet />;
    case "availableAmount":
      return <Avatar src={imageLightning} sx={{ width: "24px", height: "24px" }} />;
  }
};

interface IProps {
  hasBots: boolean;
}

const CashbackSatsList: React.FC<IProps> = ({ hasBots }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { colors } = useMUIThemeModeContext();
  const { data } = useAPICashbackSats();

  const totalAmount = data?.cashbackSats.reduce((acc, { s }) => acc + s, 0) ?? 0;
  const withdrawnAmount = 0;
  const availableAmount = totalAmount;

  if (!hasBots && (!data || data?.cashbackSats?.length === 0)) return null;

  const onInfoCardClick = (key: string) => {
    if (key === "totalAmount") navigate("/proc-bitcoin/cashback-saty");
  };

  const infoCardValueColor = (key: string) => {
    switch (key) {
      case "totalAmount":
        return "tertiary.main";
      case "withdrawnAmount":
        return colors.black;
      case "availableAmount":
        return colors.success;
    }
  };

  const infoCard = (key: string, value: number) => {
    return (
      <Card2 height={"100%"}>
        <Stack spacing={{ xs: 1, md: 3 }}>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Stack>
              <Typography fontSize={"0.75rem"} color={colors.gray500}>
                {t(`cashbackSatsList.${key}.title`)}
              </Typography>
              <MaskedModeWrapper fontSize={"1.5rem"} color={infoCardValueColor(key)} fontWeight={500}>
                <Typography fontSize={"1.5rem"} color={infoCardValueColor(key)} fontWeight={500}>
                  {renderNumber(value)}&nbsp;{t(`cashbackSatsList.sats`, { count: value })}
                </Typography>
              </MaskedModeWrapper>
            </Stack>
            {infoCardIcon(key)}
          </Stack>
          <Box>
            <Tooltip
              title={t(`cashbackSatsList.inProgress`) ?? ""}
              disableFocusListener={key === "totalAmount"}
              disableHoverListener={key === "totalAmount"}
              placement="right"
              arrow>
              <Button
                sx={{
                  p: 0,
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
                endIcon={key !== "totalAmount" && <ChevronRightIcon />}
                onClick={() => onInfoCardClick(key)}>
                {t(`cashbackSatsList.${key}.button`)}
              </Button>
            </Tooltip>
          </Box>
        </Stack>
      </Card2>
    );
  };

  return (
    <SectionCard id={"cashback-saty"} title={t("cashbackSatsList.title")}>
      {!data && (
        <Grid container columnSpacing={3}>
          <Grid item xs={12} md={4}>
            <Skeleton variant="text" height={150} width={"100%"} />
          </Grid>
          <Grid item xs={12} md={4}>
            <Skeleton variant="text" height={150} width={"100%"} />
          </Grid>
          <Grid item xs={12} md={4}>
            <Skeleton variant="text" height={150} width={"100%"} />
          </Grid>
        </Grid>
      )}
      {data?.cashbackSats?.length === 0 ? (
        <Grid container spacing={3}>
          <Grid item xs={12} lg={4}>
            {infoCard("totalAmount", totalAmount)}
          </Grid>
          <Grid item xs={12} lg={4}>
            {infoCard("withdrawnAmount", withdrawnAmount)}
          </Grid>
          <Grid item xs={12} lg={4}>
            {infoCard("availableAmount", availableAmount)}
          </Grid>
        </Grid>
      ) : null}
      {data?.cashbackSats?.length ? (
        <Grid container spacing={3}>
          <Grid item xs={12} lg={4} xl={3}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                {infoCard("totalAmount", totalAmount)}
              </Grid>
              <Grid item xs={12}>
                {infoCard("withdrawnAmount", withdrawnAmount)}
              </Grid>
              <Grid item xs={12}>
                {infoCard("availableAmount", availableAmount)}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={8} xl={9}>
            <Transactions data={data} />
          </Grid>
        </Grid>
      ) : null}
    </SectionCard>
  );
};

export default CashbackSatsList;
