import { PaletteMode, ThemeOptions, darken, lighten } from "@mui/material";

declare module "@mui/material/styles" {
  interface PaletteColor {
    background?: string;
  }
  interface SimplePaletteColorOptions {
    background?: string;
  }
  interface Palette {
    tertiary: Palette["primary"];
    success: Palette["success"];
  }
  interface PaletteOptions {
    tertiary: PaletteOptions["primary"];
  }
}

export const getThemeOptions = (mode: PaletteMode): ThemeOptions => ({
  palette: {
    mode,
    primary: {
      main: "#ff9900",
      contrastText: "#ffffff",
      background: mode === "light" ? "#FFF5E6" : darken("#ff9900", 0.8),
    },
    secondary: {
      main: lighten("#023047", mode === "light" ? 0 : 0.4),
      contrastText: "#03547C",
    },
    tertiary: {
      main: "#219EBC",
      background:  mode === "light" ? "#F3F3F3" : darken("#219EBC", 0.8),
    },
    background: {
      default: mode === "light" ? "#F0F0F0" : darken("#F0F0F0", 0.9),
    },
    success: {
      main: "#2e7d32",
      contrastText: "#ffffff",
      background: mode === "light" ? "#E6F8F0" : darken("#2e7d32", 0.7),
    },
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: 10,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          textTransform: "none",
          borderRadius: 10,
        },
        contained: {
          "&:disabled": {
            color: "white",
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          borderRadius: 10,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 10,
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          scrollBehavior: "smooth",
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: "40px",
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          paddingLeft: "20px",
          paddingRight: "20px",
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: "12px",
          paddingRight: "12px",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: mode === "dark" ? "transparent" : "#fff",
          borderRadius: "10px",
        },
        notchedOutline: {
          borderRadius: "10px",
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        text: {
          borderRadius: "10px",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
  },
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
  },
});

export const getColors = (mode: PaletteMode) =>
  mode === "light"
    ? {
        success: "#00B860",
        success500: "#079455",
        error: "#FF0000",
        slate500: "#64748B",
        neutral500: "#737373",
        purple100: "#F3E8FF",
        purple500: "#7E22CE",
        gray25: "#FCFCFD",
        gray50: "#F9FAFB",
        gray100: "#F2F4F7",
        gray200: "#EAECF0",
        gray300: "#D0D5DD",
        gray400: "#98A2B3",
        gray500: "#667085",
        gray600: "#475467",
        gray700: "#344054",
        gray800: "#1D2939",
        gray900: "#101828",
        gray950: "#0C111D",
        slate200: "#E2E8F0",
        slate700: "#334155",
        darkBlue: "#023047",
        black: "#131313",
        white: "#FFFFFF",
        blueBlack: "#110D23",
        blueGray: "#3D424E",
        deepMidnightBlue: "#111827",
        blueWhite: "#F9FBFF",
        skyBlue: "#DDE3F1",
        lightGray: "#F8F8F8",
        veryLightGray: "#F4F4F4",
        babyBlue: "#FBFCFE",
        navyBlack: "#08101F",
        mintGreen: "#D4F0E4",
        purple400: "#9747FF",
        silverGray: "#D1D5DB",
        legacyGray25: "#f8f9ff",
        legacyGray50: "#EEEFF8",
        legacyGray100: "#F1F1F1",
        legacyGray150: "#E9E9EA",
        legacyGray200: "#E5E5E5",
        legacyGray700: "#6D6D6D",
      }
    : {
        success: darken("#00B860", 0.3),
        success500: "#38f5a1",
        error: darken("#FF0000", 0.3),
        slate500: "#64748B",
        neutral500: "#737373",
        purple100: "#581c87",
        purple500: "#7E22CE",
        gray25: darken("#070b17", 0.85),
        gray50: darken("#F9FAFB", 0.9),
        gray100: "#101828",
        gray200: "#1D2939",
        gray300: "#344054",
        gray400: "#475467",
        gray500: "#667085",
        gray600: "#98A2B3",
        gray700: "#D0D5DD",
        gray800: "#EAECF0",
        gray900: "#F2F4F7",
        gray950: "#F9FAFB",
        slate200: "#1E293B",
        slate700: "#CBD5E1",
        darkBlue: "#a3defc",
        black: "#FFFFFF",
        white: "#131313",
        blueBlack: "#110D23",
        blueGray: "#98A2B3",
        deepMidnightBlue: "#111827",
        blueWhite: "#F9FBFF",
        skyBlue: "#DDE3F1",
        lightGray: darken("#F8F8F8", 0.85),
        veryLightGray: "#202020",
        babyBlue: "#FBFCFE",
        navyBlack: "#08101F",
        mintGreen: "#D4F0E4",
        purple400: "#9747FF",
        silverGray: darken("#D1D5DB", 0.7),
        legacyGray25: darken("#f8f9ff", 0.85),
        legacyGray50: darken("#EEEFF8", 0.8),
        legacyGray100: darken("#F1F1F1", 0.8),
        legacyGray150: darken("#E9E9EA", 0.6),
        legacyGray200: "#1A1A1A",
        legacyGray700: "#929292",
      };
